/* ==============================================================

Template name : Bootsnav - Multi Purpose Header
Categorie : Bootstrap Menu in CSS
Author : adamnurdin01
Version : v.1.1
Created : 2016-06-02
Last update : 2016-06-11

============================================================== */

/* MEGAMENU STYLE
=================================*/
.bootsnav .dropdown.megamenu-fw {
    position: static;
}

.bootsnav .container {
    position: relative;
}

.bootsnav .megamenu-fw .dropdown-menu {
    left: auto;
}

.bootsnav .megamenu-content {
    padding: 15px;
    width: 100% !important;
}

.bootsnav .megamenu-content .title {
    margin-top: 0;
}

.bootsnav .dropdown.megamenu-fw .dropdown-menu {
    left: 0;
    right: 0;
}

/* Navbar
=================================*/
.navbar.bootsnav {
    margin-bottom: 0;
    border-radius: 0px;
    background-color: #fff;
    border: none;
    border-bottom: solid 1px #e0e0e0;
    z-index: 9;
    padding-top: 0;
    padding-bottom: 0;
}

.navbar.bootsnav .nav > li > a {
    color: #6f6f6f;
    background-color: transparent;
}

.navbar.bootsnav .nav > li.active > a {
    background-color: transparent !important;
}

.navbar.bootsnav .nav li.megamenu-fw > a:hover,
.navbar.bootsnav .nav li.megamenu-fw > a:focus,
.navbar.bootsnav .nav li.active > a:hover,
.navbar.bootsnav .nav li.active > a:focus,
.navbar.bootsnav .nav li.active > a {
}

.navbar.bootsnav .navbar-toggle {
    background-color: #fff !important;
    border: none;
    padding: 0;
    font-size: 18px;
    position: relative;
    top: 5px;
}

.navbar.bootsnav .nav .dropdown-menu .dropdown-menu {
    top: 0;
    left: 100%;
}

.navbar.bootsnav .nav .dropdown-menu > li > a {
    white-space: normal;
}


.menu-col {
    padding: 0;
    margin: 0;
    list-style: none;
}

.menu-col li a {
    color: #6f6f6f;
}

.menu-col li a:hover,
.menu-col li a:focus {
    text-decoration: none;
}

/* Navbar Full
=================================*/
.bootsnav.navbar-full {
    padding-bottom: 10px;
    padding-top: 10px;
}

.bootsnav.navbar-full .navbar-header {
    display: block;
    width: 100%;
}

.bootsnav.navbar-full .navbar-toggle {
    display: inline-block;
    margin-right: 0;
    position: relative;
    top: 0;
    font-size: 30px;
    transition: all 1s ease-in-out;
}

.bootsnav.navbar-full .navbar-collapse {
    position: fixed;
    width: 100%;
    height: 100% !important;
    top: 0;
    left: 0;
    padding: 0;
    display: none !important;
    z-index: 9;
}

.bootsnav.navbar-full .navbar-collapse.in {
    display: block !important;
}

.bootsnav.navbar-full .navbar-collapse .nav-full {
    overflow: auto;
}

.bootsnav.navbar-full .navbar-collapse .wrap-full-menu {
    display: table-cell;
    vertical-align: middle;
    background-color: #fff;
    overflow: auto;
}

.bootsnav.navbar-full .navbar-collapse .nav-full::-webkit-scrollbar {
    width: 0;
}

.bootsnav.navbar-full .navbar-collapse .nav-full::-moz-scrollbar {
    width: 0;
}

.bootsnav.navbar-full .navbar-collapse .nav-full::-ms-scrollbar {
    width: 0;
}

.bootsnav.navbar-full .navbar-collapse .nav-full::-o-scrollbar {
    width: 0;
}


.bootsnav.navbar-full .navbar-collapse .nav {
    display: block;
    width: 100%;
    overflow: auto;
}

.bootsnav.navbar-full .navbar-collapse .nav a:hover,
.bootsnav.navbar-full .navbar-collapse .nav a:focus,
.bootsnav.navbar-full .navbar-collapse .nav a {
    background-color: transparent;
}

.bootsnav.navbar-full .navbar-collapse .nav > li {
    float: none;
    display: block;
    text-align: center;
}

.bootsnav.navbar-full .navbar-collapse .nav > li > a {
    display: table;
    margin: auto;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 24px;
    padding: 10px 15px;
}

li.close-full-menu > a {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

li.close-full-menu {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

/* Atribute Navigation
=================================*/
.attr-nav {
    float: right;
    display: inline-block;
    margin-left: 13px;
    margin-right: -15px;
}

.attr-nav > ul {
    padding: 0;
    margin: 0 0 -7px 0;
    list-style: none;
    display: inline-block;
}

.attr-nav > ul > li {
    float: left;
    display: block;
}

.attr-nav > ul > li > a {
    color: #6f6f6f;
    display: block;
    padding: 28px 15px;
    position: relative;
}

.attr-nav > ul > li > a span.badge {
    position: absolute;
    top: 50%;
    margin-top: -15px;
    right: 5px;
    font-size: 10px;
    padding: 0;
    width: 15px;
    height: 15px;
    padding-top: 2px;
}

.attr-nav > ul > li.dropdown .dropdown-menu {
    border-radius: 0px;
    box-shadow: 0px 0px 0px;
    border: solid 1px #e0e0e0;
}

.cart-list {
    padding: 0 !important;
    width: 250px !important;
}

.cart-list > li {
    position: relative;
    border-bottom: solid 1px #efefef;
    padding: 15px 15px 23px 15px !important;
}

.cart-list > li > a.photo {
    padding: 0 !important;
    margin-right: 15px;
    float: left;
    display: block;
    width: 50px;
    height: 50px;
    left: 15px;
    top: 15px;
}

.cart-list > li img {
    width: 50px;
    height: 50px;
    border: solid 1px #efefef;
}

.cart-list > li > h6 {
    margin: 0;
}

.cart-list > li > h6 > a.photo {
    padding: 0 !important;
    display: block;
}

.cart-list > li > p {
    margin-bottom: 0;
}

.cart-list > li.total {
    background-color: #f5f5f5;
    padding-bottom: 15px !important;
}

.cart-list > li.total > .btn {
    display: inline-block;
    border-bottom: solid 1px #efefef !important;
}

.cart-list > li .price {
    font-weight: bold;
}

.cart-list > li.total > span {
    padding-top: 8px;
}

/* Top Search
=================================*/
.top-search {
    background-color: #333;
    padding: 10px 0;
    display: none;
}

.top-search input.form-control {
    background-color: transparent;
    border: none;
    box-shadow: 0px 0px 0px;
    color: #fff;
    height: 40px;
    padding: 0 15px;
}

.top-search .input-group-addon {
    background-color: transparent;
    border: none;
    color: #fff;
    padding-left: 0;
    padding-right: 0;
}

.top-search .input-group-addon.close-search {
    cursor: pointer;
}

/* Side Menu
=================================*/
body {
    transition: all 0.3s ease-in-out;
}

body.on-side {
    margin-left: -280px;
}

.side {
    position: fixed;
    overflow-y: auto;
    top: 0;
    right: -280px;
    width: 280px;
    padding: 25px 30px;
    height: 100%;
    display: block;
    background-color: #333;
    transition: all 0.3s ease-in-out;
    z-index: 9;
}

.side.on {
    right: 0;
}

.side .close-side {
    float: right;
    color: #fff;
    position: relative;
    z-index: 2;
    font-size: 16px;
}

.side .widget {
    position: relative;
    z-index: 1;
    margin-bottom: 25px;
}

.side .widget .title {
    color: #fff;
    margin-bottom: 15px;
}

.side .widget .link {
    padding: 0;
    margin: 0;
    list-style: none;
}

.side .widget .link li a {
    color: #9f9f9f;
    letter-spacing: 1px;
}

.side .widget .link li a:focus,
.side .widget .link li a:hover {
    color: #fff;
    text-decoration: none;
}

/* Share
=================================*/
.navbar.bootsnav .share {
    padding: 0 30px;
    margin-bottom: 30px;
}

.navbar.bootsnav .share ul {
    display: inline-block;
    padding: 0;
    margin: 0 0 -7px 0;
    list-style: none;
}

.navbar.bootsnav .share ul > li {
    float: left;
    display: block;
    margin-right: 5px;
}

.navbar.bootsnav .share ul > li > a {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #cfcfcf;
    color: #fff;
}

/* Transparent
=================================*/
.navbar.bootsnav.navbar-fixed {
    position: fixed;
    display: block;
    width: 100%;
}

.navbar.bootsnav.no-background {
    transition: all 1s ease-in-out;
}

/* Navbar Sticky
=================================*/
.wrap-sticky {
    position: relative;
    transition: all 0.3s ease-in-out;
}

.wrap-sticky .navbar.bootsnav {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}

.wrap-sticky .navbar.bootsnav.sticked {
    position: fixed;
    transition: all 0.2s ease-in-out;
}

body.on-side .wrap-sticky .navbar.bootsnav.sticked {
    left: -280px;
}

/* Navbar Responsive
=================================*/
@media (min-width: 1024px) and (max-width:1400px) {
    body.wrap-nav-sidebar .wrapper .container {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 1024px) {
    /* General Navbar
    =================================*/
    .navbar.bootsnav .nav .dropdown-menu .dropdown-menu {
        margin-top: -2px;
    }

    .navbar.bootsnav .navbar-right .dropdown-menu .dropdown-menu {
        left: -200px;
    }

    .navbar.bootsnav .nav > li > a {
        padding: 30px 15px;
        font-weight: 600;
    }

    .navbar.bootsnav .nav > li.dropdown > a.dropdown-toggle:after {
        font-family: 'FontAwesome';
        content: "";
        margin-left: 5px;
        margin-top: 2px;
        border: 0;
        vertical-align: unset;
        display: inline;
    }

    .navbar.bootsnav li.dropdown .dropdown-menu {
        box-shadow: 0px 0px 0px;
        border-radius: 0px;
        padding: 0;
        width: 200px;
        background: #fff;
        border: solid 1px #e0e0e0;
        border-top: solid 5px;
    }

    .navbar.bootsnav li.dropdown .dropdown-menu  a:hover,
    .navbar.bootsnav li.dropdown .dropdown-menu  a:hover {
        background-color: transparent;
    }

    .dropdown-item {
        padding: 10px 15px;
        border-bottom: solid 1px #eee;
        color: #6f6f6f;

        &:last-child {
            border-bottom: none;
        }
    }

    .navbar.bootsnav .navbar-right li.dropdown .dropdown-menu li a {
        text-align: right;
    }

    .navbar.bootsnav li.dropdown .dropdown-menu .dropdown > .dropdown-toggle:after {
        font-family: 'FontAwesome';
        float: right;
        content: "\f105";
        margin-top: 0;
    }

    .navbar.bootsnav .navbar-right li.dropdown .dropdown-menu .dropdown > .dropdown-toggle:after {
        font-family: 'FontAwesome';
        float: right;
        content: "\f105";
        margin-top: 0;
    }


    .navbar.bootsnav li.dropdown .dropdown-menu .dropdown-menu {
        top: 2px;
    }

    .navbar.bootsnav .dropdown-menu.megamenu-content {
        padding: 0 15px !important;
    }

    .navbar.bootsnav .dropdown-menu.megamenu-content > li {
        padding: 25px 0 20px;
    }

    .navbar.bootsnav .dropdown-menu.megamenu-content.tabbed {
        padding: 0;
    }

    .navbar.bootsnav .dropdown-menu.megamenu-content.tabbed > li {
        padding: 0;
    }

    .navbar.bootsnav .dropdown-menu.megamenu-content .col-menu {
        padding: 0 30px;
        margin: 0 -0.5px;
        border-left: solid 1px #f0f0f0;
        border-right: solid 1px #f0f0f0;
    }

    .navbar.bootsnav .dropdown-menu.megamenu-content .col-menu:first-child {
        border-left: none;
    }

    .navbar.bootsnav .dropdown-menu.megamenu-content .col-menu:last-child {
        border-right: none;
    }

    .navbar.bootsnav .dropdown-menu.megamenu-content .content {
        display: none;
    }

    .navbar.bootsnav .dropdown-menu.megamenu-content .content .menu-col li a {
        text-align: left;
        padding: 5px 0;
        display: block;
        width: 100%;
        margin-bottom: 0;
        border-bottom: none;
        color: #6f6f6f;
    }

    .navbar.bootsnav.on .dropdown-menu.megamenu-content .content {
        display: block !important;
        height: auto !important;
    }

    /* Navbar Transparent
    =================================*/
    .navbar.bootsnav.no-background {
        background-color: transparent;
        border: none;
    }

    .navbar.bootsnav.navbar-transparent .attr-nav {
        padding-left: 15px;
        margin-left: 30px;
    }

    .navbar.bootsnav.navbar-transparent.white {
        background-color: rgba(255,255,255,0.3);
        border-bottom: solid 1px #bbb;
    }

    .navbar.navbar-inverse.bootsnav.navbar-transparent.dark,
    .navbar.bootsnav.navbar-transparent.dark {
        background-color: rgba(0,0,0,0.3);
        border-bottom: solid 1px #555;
    }

    .navbar.bootsnav.navbar-transparent.white .attr-nav {
        border-left: solid 1px #bbb;
    }

    .navbar.navbar-inverse.bootsnav.navbar-transparent.dark .attr-nav,
    .navbar.bootsnav.navbar-transparent.dark .attr-nav {
        border-left: solid 1px #555;
    }

    .navbar.bootsnav.no-background.white .attr-nav > ul > li > a,
    .navbar.bootsnav.navbar-transparent.white .attr-nav > ul > li > a,
    .navbar.bootsnav.navbar-transparent.white .nav > li > a,
    .navbar.bootsnav.no-background.white .nav > li > a {
        color: #fff;
    }

    .navbar.bootsnav.navbar-transparent.dark .attr-nav > ul > li > a,
    .navbar.bootsnav.navbar-transparent.dark .nav > li > a {
        color: #eee;
    }

    .navbar.bootsnav.navbar-fixed.navbar-transparent .logo-scrolled,
    .navbar.bootsnav.navbar-fixed.no-background .logo-scrolled {
        display: none;
    }

    .navbar.bootsnav.navbar-fixed.navbar-transparent .logo-display,
    .navbar.bootsnav.navbar-fixed.no-background .logo-display {
        display: block;
    }

    .navbar.bootsnav.navbar-fixed .logo-display {
        display: none;
    }

    .navbar.bootsnav.navbar-fixed .logo-scrolled {
        display: block;
    }

    /* Atribute Navigation
    =================================*/
    .attr-nav > ul > li.dropdown .dropdown-menu {
        margin-top: 0;
        margin-left: 55px;
        width: 250px;
        left: -250px;
    }

    /* Menu Center
    =================================*/
    .navbar.bootsnav.menu-center .container {
        position: relative;
    }

    .navbar.bootsnav.menu-center .navbar-center {
        float: none;
        margin: 0 auto;
        display: table;
        table-layout: fixed;
    }

    .navbar.bootsnav.menu-center .navbar-header,
    .navbar.bootsnav.menu-center .attr-nav {
        position: absolute;
    }

    .navbar.bootsnav.menu-center .attr-nav {
        right: 15px;
    }

    /* Navbar Brand top
    =================================*/
    .bootsnav.navbar-brand-top .navbar-header {
        display: block;
        width: 100%;
        text-align: center;
    }

    .bootsnav.navbar-brand-top .nav > li.dropdown > .dropdown-menu {
        margin-top: 2px;
    }

    .bootsnav.navbar-brand-top .nav > li.dropdown.megamenu-fw > .dropdown-menu {
        margin-top: 0;
    }

    .bootsnav.navbar-brand-top .navbar-header .navbar-brand {
        display: inline-block;
        float: none;
        margin: 0;
    }

    .bootsnav.navbar-brand-top .navbar-collapse {
        text-align: center;
    }

    .bootsnav.navbar-brand-top .nav {
        display: inline-block;
        float: none;
        margin: 0 0 -5px 0;
    }

    /* Navbar Center
    =================================*/
    .bootsnav.brand-center .navbar-header {
        display: block;
        width: 100%;
        position: absolute;
        text-align: center;
        top: 0;
        left: 0;
    }

    .bootsnav.brand-center .navbar-brand {
        display: inline-block;
        float: none;
    }

    .bootsnav.brand-center .navbar-collapse {
        text-align: center;
        display: inline-block;
        padding-left: 0;
        padding-right: 0;
    }

    .bootsnav.brand-center .nav > li.dropdown > .dropdown-menu {
        margin-top: 2px;
    }

    .bootsnav.brand-center .nav > li.dropdown.megamenu-fw > .dropdown-menu {
        margin-top: 0;
    }

    .bootsnav.brand-center .navbar-collapse .col-half {
        width: 50%;
        float: left;
        display: block;
    }

    .bootsnav.brand-center .navbar-collapse .col-half.left {
        text-align: right;
        padding-right: 100px;
    }

    .bootsnav.brand-center .navbar-collapse .col-half.right {
        text-align: left;
        padding-left: 100px;
    }

    .bootsnav.brand-center .nav {
        float: none !important;
        margin-bottom: -5px !important;
        display: inline-block !important;
    }

    .bootsnav.brand-center .navbar-right {
        margin: 0;
    }

    .bootsnav.brand-center.center-side .navbar-collapse .col-half.left {
        text-align: left;
        padding-right: 100px;
    }

    .bootsnav.brand-center.center-side .navbar-collapse .col-half.right {
        text-align: right;
        padding-left: 100px;
    }

    /* Navbar Sidebar
    =================================*/
    body.wrap-nav-sidebar .wrapper {
        padding-left: 260px;
        overflow-x: hidden;
    }

    .bootsnav.navbar-sidebar {
        position: fixed;
        width: 260px;
        overflow: hidden;
        left: 0;
        padding: 0 0 0 0 !important;
        background: #fff;
        box-shadow: 1px 0px 1px 0px #eee;
    }

    .bootsnav.navbar-sidebar .scroller {
        width: 280px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .bootsnav.navbar-sidebar .container-fluid,
    .bootsnav.navbar-sidebar .container {
        padding: 0 !important;
    }

    .bootsnav.navbar-sidebar .navbar-header {
        float: none;
        display: block;
        width: 260px;
        padding: 10px 15px;
        margin: 10px 0 0 0 !important;
    }

    .bootsnav.navbar-sidebar .navbar-collapse {
        padding: 0 !important;
        width: 260px;
    }

    .bootsnav.navbar-sidebar .nav {
        float: none;
        display: block;
        width: 100%;
        padding: 0 15px !important;
        margin: 0 0 30px 0;
    }

    .bootsnav.navbar-sidebar .nav li {
        float: none !important;
    }

    .bootsnav.navbar-sidebar .nav > li > a {
        padding: 10px 15px;
        font-weight: bold;
    }

    .bootsnav.navbar-sidebar .nav > li.dropdown > a:after {
        float: right;
    }

    .bootsnav.navbar-sidebar .nav li.dropdown .dropdown-menu {
        left: 100%;
        top: 0;
        position: relative !important;
        left: 0 !important;
        width: 100% !important;
        height: auto !important;
        background-color: transparent;
        border: none !important;
        padding: 0;
        box-shadow: 0px 0px 0px;
    }

    .bootsnav.navbar-sidebar .nav .megamenu-content .col-menu {
        border: none !important;
    }

    .bootsnav.navbar-sidebar .nav > li.dropdown > .dropdown-menu {
        margin-bottom: 15px;
    }

    .bootsnav.navbar-sidebar .nav li.dropdown .dropdown-menu {
        padding-left: 0;
        float: none;
        margin-bottom: 0;
    }

    .bootsnav.navbar-sidebar .nav li.dropdown .dropdown-menu li a {
        padding: 5px 15px;
        color: #6f6f6f;
        border: none;
    }

    .bootsnav.navbar-sidebar .nav li.dropdown .dropdown-menu .dropdown-menu {
        padding-left: 15px;
        margin-top: 0;
    }

    .bootsnav.navbar-sidebar .nav li.dropdown .dropdown-menu li.dropdown > a:before {
        font-family: 'FontAwesome';
        content: "\f105";
        float: right;
    }

    .bootsnav.navbar-sidebar .nav li.dropdown.on .dropdown-menu li.dropdown.on > a:before {
        content: "\f107";
    }

    .bootsnav.navbar-sidebar .dropdown-menu.megamenu-content > li {
        padding: 0 !important;
    }

    .bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu {
        display: block;
        float: none !important;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu .title {
        padding: 7px 0;
        text-transform: none;
        font-weight: 400;
        letter-spacing: 0px;
        margin-bottom: 0;
        cursor: pointer;
        color: #6f6f6f;
    }

    .bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu .title:before {
        font-family: 'FontAwesome';
        content: "\f105";
        float: right;
    }

    .bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu.on .title:before {
        content: "\f107";
    }

    .bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu {
        border: none;
    }

    .bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu .content {
        padding: 0 0 0 15px;
    }

    .bootsnav.navbar-sidebar .dropdown .megamenu-content .col-menu .menu-col li a {
        padding: 3px 0 !important;
    }
}

@media (max-width: 992px) {
    /* Navbar Responsive
    =================================*/
    .navbar.bootsnav .navbar-brand {
        display: inline-block;
        float: none !important;
        margin: 0 !important;
    }

    .navbar.bootsnav .navbar-header {
        float: none;
        display: block;
        text-align: center;
        padding-left: 30px;
        padding-right: 30px;
    }

    .navbar.bootsnav .navbar-toggle {
        display: inline-block;
        float: left;
        margin-right: -200px;
        margin-top: 10px;
    }

    .navbar.bootsnav .navbar-collapse {
        border: none;
        margin-bottom: 0;
    }

    .navbar.bootsnav.no-full .navbar-collapse {
        max-height: 350px;
        overflow-y: auto !important;
    }

    .navbar.bootsnav .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar.bootsnav .navbar-collapse.collapse.in {
        display: block !important;
    }

    .navbar.bootsnav .navbar-nav {
        float: none !important;
        padding-left: 30px;
        padding-right: 30px;
        margin: 0px -15px;
    }

    .navbar.bootsnav .navbar-nav > li {
        float: none;
    }

    .navbar.bootsnav li.dropdown a.dropdown-toggle:after {
        font-family: 'FontAwesome';
        content: "\f105";
        float: right;
        font-size: 16px;
        margin-left: 10px;
        border:0;
    }

    .navbar.bootsnav li.dropdown.on > a.dropdown-toggle:after {
        content: "\f107";
    }

    .navbar.bootsnav .navbar-nav > li > a {
        display: block;
        width: 100%;
        border-bottom: solid 1px #e0e0e0;
        padding: 10px 0;
        border-top: solid 1px #e0e0e0;
        margin-bottom: -1px;
    }

    .navbar.bootsnav .navbar-nav > li:first-child > a {
        border-top: none;
    }

    .navbar.bootsnav .navbar-.navbar-left > li:last-child > .dropdown-menu {
        border-bottom: solid 1px #e0e0e0;
    }

    .navbar.bootsnav .nav li.dropdown li a.dropdown-toggle {
        float: none !important;
        position: relative;
        display: block;
        width: 100%;
    }

    .navbar.bootsnav .nav li.dropdown .dropdown-menu {
        width: 100%;
        position: relative !important;
        background-color: transparent;
        float: none;
        border: none;
        padding: 0 0 0 15px !important;
        margin: 0 0 -1px 0 !important;
        box-shadow: 0px 0px 0px;
        border-radius: 0px 0px 0px;
    }

    .navbar.bootsnav .nav li.dropdown .dropdown-menu > li > a {
        display: block;
        width: 100%;
        border-bottom: solid 1px #e0e0e0;
        padding: 10px 0;
        color: #6f6f6f;
    }

    .navbar.bootsnav .nav .dropdown-menu li a:hover,
    .navbar.bootsnav .nav .dropdown-menu li a:focus {
        background-color: transparent;
    }

    .navbar.bootsnav .nav .dropdown-menu .dropdown-menu {
        float: none !important;
        left: 0;
        padding: 0 0 0 15px;
        position: relative;
        background: transparent;
        width: 100%;
    }

    .navbar.bootsnav .nav .dropdown-menu li.dropdown.on > .dropdown-menu {
        display: inline-block;
        margin-top: -10px;
    }

    .navbar.bootsnav li.dropdown .dropdown-menu li.dropdown > a.dropdown-toggle:after {
        display: none;
    }

    .navbar.bootsnav .dropdown .megamenu-content .col-menu .title {
        padding: 10px 15px 10px 0;
        line-height: 24px;
        text-transform: none;
        font-weight: 400;
        letter-spacing: 0px;
        margin-bottom: 0;
        cursor: pointer;
        border-bottom: solid 1px #e0e0e0;
        color: #6f6f6f;
    }

    .navbar.bootsnav .dropdown .megamenu-content .col-menu ul > li > a {
        display: block;
        width: 100%;
        border-bottom: solid 1px #e0e0e0;
        padding: 8px 0;
    }

    .navbar.bootsnav .dropdown .megamenu-content .col-menu .title:before {
        font-family: 'FontAwesome';
        content: "\f105";
        float: right;
        font-size: 16px;
        margin-left: 10px;
        position: relative;
        right: -15px;
    }

    .navbar.bootsnav .dropdown .megamenu-content .col-menu:last-child .title {
        border-bottom: none;
    }

    .navbar.bootsnav .dropdown .megamenu-content .col-menu.on:last-child .title {
        border-bottom: solid 1px #e0e0e0;
    }

    .navbar.bootsnav .dropdown .megamenu-content .col-menu:last-child .menu-col li:last-child a {
        border-bottom: none;
    }

    .navbar.bootsnav .dropdown .megamenu-content .col-menu.on .title:before {
        content: "\f107";
    }

    .navbar.bootsnav .dropdown .megamenu-content .col-menu .content {
        padding: 0 0 0 15px;
    }

    .bootsnav.brand-center .navbar-collapse {
        display: block;
    }

    .bootsnav.brand-center .nav {
        margin-bottom: 0px !important;
    }

    .bootsnav.brand-center .navbar-collapse .col-half {
        width: 100%;
        float: none;
        display: block;
    }

    .bootsnav.brand-center .navbar-collapse .col-half.left {
        margin-bottom: 0;
    }

    .bootsnav .megamenu-content {
        padding: 0;
    }

    .bootsnav .megamenu-content .col-menu {
        padding-bottom: 0;
    }

    .bootsnav .megamenu-content .title {
        cursor: pointer;
        display: block;
        padding: 10px 15px;
        margin-bottom: 0;
        font-weight: normal;
    }

    .bootsnav .megamenu-content .content {
        display: none;
    }

    .attr-nav {
        position: absolute;
        right: 60px;
    }

    .attr-nav > ul {
        padding: 0;
        margin: 0 -15px -7px 0;
    }

    .attr-nav > ul > li > a {
        padding: 16px 15px 15px;
    }

    .attr-nav > ul > li.dropdown > a.dropdown-toggle:before {
        display: none;
    }

    .attr-nav > ul > li.dropdown .dropdown-menu {
        margin-top: 2px;
        margin-left: 55px;
        width: 250px;
        left: -250px;
        border-top: solid 5px;
    }

    .top-search .container {
        padding: 0 45px;
    }

    /* Navbar full Responsive
    =================================*/
    .bootsnav.navbar-full .nav {
        margin-left: 0;
    }

    .bootsnav.navbar-full .nav > li > a {
        border: none;
    }

    .bootsnav.navbar-full .navbar-brand {
        float: left !important;
        padding-left: 0;
    }

    .bootsnav.navbar-full .navbar-toggle {
        display: inline-block;
        float: right;
        margin-right: 0;
        margin-top: 10px;
    }

    .bootsnav.navbar-full .navbar-header {
        padding-left: 15px;
        padding-right: 15px;
    }

    /* Navbar Sidebar
    =================================*/
    .navbar.bootsnav.navbar-sidebar .share {
        padding: 30px 15px;
        margin-bottom: 0;
    }

    /* Tabs
    =================================*/
    .navbar.bootsnav .megamenu-content.tabbed {
        padding-left: 0;
    }

    .navbar.bootsnav .tabbed > li {
        padding: 25px 0;
        margin-left: -15px !important;
    }

    /* Mobile Navigation
    =================================*/
    body > .wrapper {
        transition: all 0.3s ease-in-out;
    }

    body.side-right > .wrapper {
        margin-left: 280px;
        margin-right: -280px !important;
    }

    .navbar.bootsnav.navbar-mobile .navbar-collapse {
        position: fixed;
        overflow-y: auto !important;
        overflow-x: hidden !important;
        display: block;
        background: #fff;
        z-index: 99;
        width: 280px;
        height: 100% !important;
        left: -280px;
        top: 0;
        padding: 0;
        transition: all 0.3s ease-in-out;
    }

    .navbar.bootsnav.navbar-mobile .navbar-collapse.in {
        left: 0;
    }

    .navbar.bootsnav.navbar-mobile .nav {
        width: 293px;
        padding-right: 0;
        padding-left: 15px;
    }

    .navbar.bootsnav.navbar-mobile .nav > li > a {
        padding: 15px 15px;
    }

    .navbar.bootsnav.navbar-mobile .nav .dropdown-menu > li > a {
        padding-right: 15px !important;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .navbar.bootsnav.navbar-mobile .nav .dropdown-menu .col-menu .title {
        padding-right: 30px !important;
        padding-top: 13px !important;
        padding-bottom: 13px !important;
    }

    .navbar.bootsnav.navbar-mobile .nav .dropdown-menu .col-menu .menu-col li a {
        padding-top: 13px !important;
        padding-bottom: 13px !important;
    }

    .navbar.bootsnav.navbar-mobile .navbar-collapse [class*=' col-'] {
        width: 100%;
    }

    .navbar.bootsnav.navbar-fixed .logo-scrolled {
        display: block !important;
    }

    .navbar.bootsnav.navbar-fixed .logo-display {
        display: none !important;
    }

    .navbar.bootsnav.navbar-mobile .tab-menu,
    .navbar.bootsnav.navbar-mobile .tab-content {
        width: 100%;
        display: block;
    }
}

@media (max-width: 767px) {
    .navbar.bootsnav .navbar-header {
        padding-left: 15px;
        padding-right: 15px;
    }

    .navbar.bootsnav .navbar-nav {
        padding-left: 15px;
        padding-right: 15px;
    }

    .attr-nav {
        right: 30px;
    }

    .attr-nav > ul {
        margin-right: -10px;
    }

    .attr-nav > ul > li > a {
        padding: 16px 10px 15px;
        padding-left: 0 !important;
    }

    .attr-nav > ul > li.dropdown .dropdown-menu {
        left: -275px;
    }

    .top-search .container {
        padding: 0 15px;
    }

    .bootsnav.navbar-full .navbar-collapse {
        left: 15px;
    }

    .bootsnav.navbar-full .navbar-header {
        padding-right: 0;
    }

    .bootsnav.navbar-full .navbar-toggle {
        margin-right: -15px;
    }

    .bootsnav.navbar-full .nav > li > a {
        font-size: 18px !important;
        line-height: 24px !important;
        padding: 5px 10px !important;
    }

    /* Navbar Sidebar
    =================================*/
    .navbar.bootsnav.navbar-sidebar .share {
        padding: 30px 15px !important;
    }

    /* Navbar Sidebar
    =================================*/
    .navbar.bootsnav.navbar-sidebar .share {
        padding: 30px 0 !important;
        margin-bottom: 0;
    }

    .navbar.bootsnav.navbar-mobile.navbar-sidebar .share {
        padding: 30px 15px !important;
        margin-bottom: 0;
    }

    /* Mobile Navigation
    =================================*/
    body.side-right > .wrapper {
        margin-left: 280px;
        margin-right: -280px !important;
    }

    .navbar.bootsnav.navbar-mobile .navbar-collapse {
        margin-left: 0;
    }

    .navbar.bootsnav.navbar-mobile .nav {
        margin-left: -15px;
    }

    .navbar.bootsnav.navbar-mobile .nav {
        border-top: solid 1px #fff;
    }

    li.close-full-menu {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
}

@media (min-width: 480px) and (max-width: 640px) {
    .bootsnav.navbar-full .nav {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

@media only screen and (min-width:992px) {
    .navbar-expand-lg {
        .navbar-collapse {
            justify-content: flex-end;
        }
    }
}
