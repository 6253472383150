.is_clickable {
	cursor:pointer;
}
.nstSlider {
	cursor:pointer;
	border-radius:10px;
	position:relative;
	width:100%;
	height:12px;
	background:#e1e7ec;
}
.nstSlider .leftGrip.gripHighlighted, .nstSlider .rightGrip.gripHighlighted {
	background:#134;
}
.nstSlider .leftGrip, .nstSlider .rightGrip {
	position:absolute;
	border-radius:10px;
	width:20px;
	height:20px;
	background:#fff;
	box-shadow: 0 1px 1px 0 #b8b8b8;
}
.nstSlider .bar {
	-webkit-border-radius:10px;
	-moz-border-radius:10px;
	border-radius:10px;
	position:absolute;
	background:#515151;
	height:12px;
	top:0;
}
.nstSlider .highlightPanel {
	transition:all 500ms ease-in-out;
	border-radius:10px;
	position:absolute;
	background:#e87722;
	height:20px;
	top:0;
}
