.galleryCol {

    .swiper-container {
        min-height: 240px;

        @media only screen and (min-width:768px) {
            min-height: 480px;
        }
    }

    .swiper-slide {
        img {
            width: 100%;
            height: auto;
        }
    }

    .swiper-pagination-bullet-active {
        background: var(--backgroundPrimary);
    }
}

.property-details {
    padding-top: 15px;

    .pro-list {
        margin-left: 5px;

        &.features {
            columns: 2;

            & > li.features_item {
                &--numeric {
                    list-style-image: none;
                    position: relative;
                    padding-left: 25px;

                    span {
                        position: absolute;
                        display: block;
                        width: 20px;
                        height: 20px;
                        left: 0;
                        background: var(--backgroundPrimary);
                        color: var(--colorButtonPrimary);
                        border-radius: 20px;
                        text-align: center;
                        line-height: 20px;
                    }
                }
            }
        }

        li {
            list-style-position: inside;
        }
    }
}

.property-details-price {
    color: var(--priceColor);
    font-size: 2rem;
    margin: 30px 0;

    &_suffix {
        font-size: 0.8rem;

        &--offers-from {
            display: block;
        }
    }
}

.agent-contact-cards {
    border: solid 2px var(--backgroundPrimary);
    margin-bottom: 1em;
    border-radius: 6px;
}

@media only screen and (min-width:768px) {
    .property-details {
        margin-left: 15px;

        .pro-list {
            &.features {
                columns: 3;
            }
        }
    }
}
