.socialLinks {
    display: flex;
    padding: 0 0 35px;
    align-items: center;
    justify-content: flex-start;

    a {
        margin: auto 10px;
    }

    .fa {
        display: block;
        margin: auto;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        text-align: center;
        font-size: 24px;
        line-height: 40px;
        color: #fff;

        &.fa-facebook {
            background: #3e5b98;
        }

        &.fa-twitter {
            background: #4da7de;
        }

        &.fa-whatsapp {
            background: #20b038;
        }
    }
}
