
.agent-contact-card {
    text-align: center;
    padding: 1em;

    &__image-container {
        max-width:45%;
        margin:0 auto 1em;
    }

    &__image {
    }

    &__agent-name {
        font-size: 1.25rem;
    }

    &__agent-title{
        font-size:0.875rem;
    }
}
