.breadcrumbs {
    margin-bottom: 15px;
    display: none;
    height:40px;

    &_item {
        color: var(--backgroundPrimary);
        line-height: 40px;
        font-size: 0.8rem;

        &--back {
            text-align: right;
            margin-right: 15px;
            display: inline-block;
            height: 40px;
            line-height: 40px;
        }
    }

    &_seperator {
        color: var(--backgroundPrimary);
        font-size: 0.8rem;
    }
}

@media only screen and (min-width:768px) {
    .breadcrumbs {
        display: block;

    }
}
