.contact-info-button {
    background: var(--backgroundPrimary);
    color: var(--colorButtonPrimary);
    display: block;
    padding: 0.75em 1.25em;
    margin: 1em auto;
    border-radius: 0.25em;
    text-transform: uppercase;
    font-weight: bold;
    border: 0;
}

.contact-info-container {
}

.contact-info {
    text-align: center;
    padding-bottom: 1em;

    &__label {
        font-weight: bold;
    }

    &__link {
        white-space: nowrap;
        padding-left: 0.5em;
    }
}
