/* CSS-Tricks Example by Chris Coyier http://css-tricks.com */
.invoice-sending a .fa {
     display: block;
     color: #ed2a28;
     font-size: 40px;
     margin-bottom: 8px;
}
.invoice-sending a {
     display: block;
     color: #222;
     border: 8px solid rgba(0,0,0,0.1);
     background: #fff;
     padding: 10px 0;
     font-size: 16px;
     margin-bottom: 30px;
}
.invoice-sending a b{
     font-size: 20px;
     font-weight:600;
}
.invoice-sending a b{
     font-size: 20px;
     font-weight:600;
}
.invoice-sending a:hover{
     background: #ed2a28;
     color: #fff;
}
.invoice-sending a:hover .fa{
     color: #fff;
}
 #invoice .container{
     background: #fff;
     padding: 15px;
 }
 #invoice textarea {
     overflow: hidden;
     resize: none;
     border: 1px solid transparent;
}
 #invoice table {
     border-collapse: collapse;
}
 #invoice table td, #invoice table th {
     border: 1px solid #ddd;
     padding: 5px;
}
 .invoice-header {
     width: 100%;
     margin: 20px 0;
     background: #222;
     text-align: center;
     color: white;
     text-decoration: uppercase;
     letter-spacing: 20px;
     padding: 8px 0px;
}
 #address {
     width: 250px;
     height: 120px;
     float: left;
     margin-bottom: 30px;
}
 #customer {
     overflow: hidden;
}
 #logo {
     text-align: right;
     float: right;
     position: relative;
     margin-top: 25px;
     border: 1px solid #fff;
     max-width: 540px;
     max-height: 100px;
     overflow: hidden;
}
 #logo:hover, #logo.edit {
     border: 1px solid #000;
     margin-top: 0px;
     max-height: 125px;
}
 #logoctr {
     display: none;
}
 #logo:hover #logoctr, #logo.edit #logoctr {
     display: block;
     text-align: right;
     line-height: 25px;
     background: #eee;
     padding: 0 5px;
}
 #logohelp {
     text-align: left;
     display: none;
     font-style: italic;
     padding: 10px 5px;
}
 #logohelp input {
     margin-bottom: 5px;
}
 .edit #logohelp {
     display: block;
}
 .edit #save-logo, .edit #cancel-logo {
     display: inline;
}
 .edit #image, #save-logo, #cancel-logo, .edit #change-logo, .edit #delete-logo {
     display: none;
}
 #customer-title {
     font-size: 20px;
     font-weight: bold;
     float: left;
}
 #meta {
     margin-top: 1px;
     width: 300px;
     float: right;
}
 #meta td {
     text-align: right;
}
 #meta td.meta-head {
     text-align: left;
     background: #eee;
}
 #meta td textarea {
     width: 100%;
     height: 20px;
     text-align: right;
}
 #items {
     clear: both;
     width: 100%;
     margin: 30px 0 0 0;
     border: 1px solid #ddd;
}
 #items th {
     background: #eee;
}
 #items textarea {
     width: 80px;
     height: 50px;
}
 #items tr.item-row td {
     border: 0;
     vertical-align: top;
}
 #items td.description {
     width: 300px;
}
 #items td.item-name {
     width: 175px;
}
 #items td.description textarea, #items td.item-name textarea {
     width: 100%;
}
 #items td.total-line {
     border-right: 0;
     text-align: right;
}
 #items td.total-value {
     border-left: 0;
     padding: 10px;
}
 #items td.total-value textarea {
     height: 20px;
     background: none;
}
 #items td.balance {
     background: #eee;
}
 #items td.blank {
     border: 0;
}
 #terms {
     text-align: center;
     margin: 20px 0 0 0;
}
 #terms h5 {
     text-transform: uppercase;
     letter-spacing: 10px;
     border-bottom: 1px solid #ddd;
     padding: 0 0 8px 0;
     margin: 0 0 8px 0;
}
 #terms textarea {
     width: 100%;
     text-align: center;
}
  #invoice textarea:hover,  
  #invoice textarea:focus, 
  #items td.total-value textarea:hover, 
  #items td.total-value textarea:focus, 
  .delete:hover {
     background-color:#f6f6f6;
     border-color:#ed2a28;
}
 .delete-wpr {
     position: relative;
}
 .delete {
     display: block;
     color: #000;
     text-decoration: none;
     position: absolute;
     background: #EEEEEE;
     font-weight: bold;
     padding: 0px 3px;
     border: 1px solid;
     top: -6px;
     left: -22px;
     font-family: Verdana;
     font-size: 12px;
}
.delete {
  display: none;
}

/************* Invoice - 2 ***************/
 .invoice2 {
     background: #fff;
     padding: 15px;
}
/* content editable */
 *[contenteditable] {
     border-radius: 0.25em;
     min-width: 1em;
     outline: 0;
}
 *[contenteditable] {
     cursor: pointer;
}
 *[contenteditable]:hover, 
 *[contenteditable]:focus, 
 .invoice-body td:hover *[contenteditable], 
 .invoice-body td:focus *[contenteditable], 
 .invoice-body img.hover {
     box-shadow: 0 0 0 1px #ed2a28; 
}
.invoice-body span[contenteditable] {
     display: inline-block;
}
/* heading */
 .invoice-body h1 {
     font: bold 100% sans-serif;
     letter-spacing: 0.5em;
     text-align: center;
     text-transform: uppercase;
}
/* table */
.invoice-body table {
     font-size: 100%;
     table-layout: fixed;
     width: 100%;
}
.invoice-body table {
     border-collapse: separate !important;
     border-spacing: 2px;
}
.invoice-body th, .invoice-body td {
     border-width: 1px;
     padding: 0.5em;
     position: relative;
     text-align: left;
}
.invoice-body th, .invoice-body td {
     border-radius: 0.25em;
     border-style: solid;
}
.invoice-body th {
     background: #EEE;
     border-color: #BBB;
}
.invoice-body td {
     border-color: #DDD;
}
/* page */
 #invoice2-header {
     margin: 0 0 3em;
}
 #invoice2-header:after {
     clear: both;
     content: "";
     display: table;
}
#invoice2-header h1 {
     background: #000;
     border-radius: 0.25em;
     color: #FFF;
     margin: 0 0 1em;
     padding: 8px 0;
     font-size: 20px;
     font-weight: 400;
     display: block;
     text-align: center;
     text-transform: uppercase;
     letter-spacing: 2px;
}
 #invoice2-header address {
     float: left;
     font-size: 100%;
     font-style: normal;
     line-height: 1.25;
     margin: 0 1em 1em 0;
}
 #invoice2-header address label{
     color: #222;
     font-size: 18px;
     text-transform: uppercase;
}
 #invoice2-header span, #invoice2-header img {
     display: block;
     float: right;
}
 #invoice2-header span {
     margin: 0 0 1em 1em;
     max-height: 25%;
     max-width: 60%;
     position: relative;
}
 #invoice2-header img {
     max-height: 100%;
     max-width: 100%;
}
 #invoice2-header input {
     cursor: pointer;
     -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
     height: 100%;
     left: 0;
     opacity: 0;
     position: absolute;
     top: 0;
     width: 100%;
}
/* article.invoice-body */
 article.invoice-body, article.invoice-body address, table.meta, table.inventory {
     margin: 0 0 3em;
}
 article.invoice-body:after {
     clear: both;
     content: "";
     display: table;
}
 article.invoice-body h1 {
     clip: rect(0 0 0 0);
     position: absolute;
}
 article.invoice-body address {
     float: left;
     font-size: 125%;
     font-weight: bold;
}
/* table meta & balance */
 table.meta, table.balance {
     float: right;
     width: 36%;
}
 table.meta:after, table.balance:after {
     clear: both;
     content: "";
     display: table;
}
/* table meta */
 table.meta th {
     width: 40%;
}
 table.meta td {
     width: 60%;
}
/* table items */
 table.inventory {
     clear: both;
     width: 100%;
}
 table.inventory th {
     font-weight: bold;
     text-align: center;
}
 table.inventory td:nth-child(1) {
     width: 26%;
}
 table.inventory td:nth-child(2) {
     width: 38%;
}
 table.inventory td:nth-child(3) {
     text-align: right;
     width: 12%;
}
 table.inventory td:nth-child(4) {
     text-align: right;
     width: 12%;
}
 table.inventory td:nth-child(5) {
     text-align: right;
     width: 12%;
}
/* table balance */
 table.balance th, table.balance td {
     width: 50%;
}
 table.balance td {
     text-align: right;
}
/* aside */
.invoice-body aside h1 {
     border: none;
     border-width: 0 0 1px;
     margin: 0 0 1em;
}
.invoice-body aside h1 {
     border-color: #999;
     border-bottom-style: solid;
}
/* javascript */
.add, .cut {
     border-width: 1px;
     display: block;
     font-size: 14px;
     padding: 0;
     float: left;
     text-align: center;
     width: 25px;
     height: 25px;
     line-height: 25px;
     color:#fff;
     background-color: #ed2a28; 
     cursor: pointer;
}
 .add {
     margin: -2.5em 0 0;
}
 .add:hover,
 .cut:hover {
     background: #222;
     color: #fff;
}
 .cut {
     opacity: 0;
     position: absolute;
     top: 0;
     left: -1.5em;
     -webkit-transition: opacity 100ms ease-in;
}
.invoice-body tr:hover .cut {
     opacity: 1;
}
.invoice2 aside h1 span {
     font-size: 16px;
     color: #888;
     text-transform: uppercase;
}
.invoice2 aside p {
     font-size: 14px;
}
 @media print {
     .invoice-body span:empty {
         display: none;
    }
     .add, .cut {
         display: none;
    }
}
 @page {
     margin: 0;
}
