.results-filters {
    background-color: #ccc;
    padding: 1.5rem;
    height: auto;

    .container{
        max-width:960px;
    }
}

.single-query {
    select {
        background-color: #fff;
    }
}

.resultsPage {
    .container {
        max-width: 960px;
        //margin: auto;
    }
}

.results {
    width: 100%;
}
