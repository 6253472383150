
.filterTabs {
    border-radius: 0 0 4px 4px;
    border: 0;
    margin-top: 20px;

    .propertyTypesNav {
        background: #fff;
        min-height: 30px;

        .navbar-toggler {
        }
    }

    &.property-tab {
        .nav-tabs {
            display: flex;
            flex-direction: column;
            border: solid 1px #fff;
            border-bottom: 0;

            .nav-item {
                flex: 1;
                text-align: left;
            }

            .nav-link {
                border-bottom: 1px solid #fff;
            }
        }
    }
}

.propertyFilters {
    background: rgba(255,255,255,0.6);
    padding: 1.5rem 1.5rem 1.5rem;
    margin: 0 auto 20px;

    &_listingType {
        display: flex;
        margin: auto auto .5rem;
        justify-content: center;
    }

    &_moreFilters {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 40px;
    }

    &_filtersToggle {
        background: var(--backgroundPrimary);
        color: #fff;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin-left: 5px;
    }

    &_multiSelect {
        color: #495057;
    }

    &_submit {
        margin-top: 1.5rem;
    }
}


@media only screen and (min-width:768px) {
    .filterTabs {

        .propertyTypesNav {
            .navbar-toggler {
                display: none;
            }
        }

        &.property-tab {
            .nav-tabs {
                flex-direction: row;

                .nav-item {
                    text-align: center;
                }
            }

            .nav-link {
                border-bottom: 0;
                border-right: 1px solid #fff;
            }
        }
    }

    .propertyFilters {
        &_listingType {
            margin-top: calc(1.5em + 0.5rem);
            justify-content: flex-start;
        }

        &_moreFilters {
            display: none;
        }
    }
}
