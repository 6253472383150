.listingResult {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: #fff;
    width: 100%;
    border-top: 3px solid var(--backgroundPrimary);
    max-width: 960px;
    margin: 40px auto 0;
    position: relative;

    label {
        font-size: 11px;
        font-weight: normal;
        padding: 6px 15px;
        text-transform: uppercase;
    }

    p {
        font-size: 14px;
        line-height: 1.2;
    }

    &_imageContainer {
        display: flex;
        justify-content: flex-start;
        padding-left: 0;
        padding-right: 0;
        margin-top: -3px;
        max-height: 243px;
        min-width: 320px;

        img {
            max-width: 100%;
        }
    }

    &_image {
        width: 100%;
        height: auto;
    }

    &_propertyInfo {
        display: flex;
    }

    div[class^=col] {
        position: static;
    }

    &_label {
        background: var(--backgroundPrimary);
        color: #fff;
        flex: 0;
        align-self: flex-end;
        position: relative;

        &:first-of-type {
            margin-top: 22px;
        }

        &:before {
            background: transparent;
            border-bottom: 14px solid transparent;
            border-top: 14px solid transparent;
            bottom: 0;
            content: "";
            height: 0;
            left: -14px;
            position: absolute;
            top: 0;
            width: 0;
            border-right: 14px solid var(--backgroundPrimary);
        }
    }

    &_title {
        padding-top: 20px;
        font-size: 15px;
        text-transform: capitalize;
    }

    &_address {
        margin: 0 0 5px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &_price {
        color: var(--priceColor);
        font-size: 18px;
        order: -1;
        margin-bottom: 5px;
        display: block;

        &--suffix {
            font-size: 11px;
        }
    }

    &_description {
        display: none;
    }

    &_facility {
        display: flex;
        flex-direction: column;
    }

    &_feature {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 45px;
        padding: 0 0 0 10px;
        margin-left: auto;
        min-width: 150px;

        &:first-child {
            margin-top: 50px;
        }
    }

    &_featureValue {
        background: var(--backgroundPrimary);
        color: #fff;
        display: block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 15px;
    }
}

@media only screen and (min-width:768px) {
    .listingResult {
        flex-direction: row;

        p {
            line-height: 1.8em;
        }

        &_propertyInfo {
            max-height: 240px;
            overflow: hidden;
        }

        &_title {
            font-size: 20px;
        }

        &_address {
            white-space: nowrap;
        }

        &_price {
            order: 0;
        }

        &_description {
            display: block;
            max-height: 5.4em;
            overflow: hidden;

            p {
                margin-bottom: 5px;
                line-height: 1.3em;

                &:last-child {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
