#privacyPolicy {
    padding: 3rem 0;

    h2 {
        font-size: 1.25rem;
        margin: 0.25em auto;
        max-width: 55ch;
    }

    p {
        font-size: 1rem;
        line-height: 1.4;
        margin-bottom: 1em;
        max-width: 79ch;
        margin-left: auto;
        margin-right: auto;
    }
}
