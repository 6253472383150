.expander {
    position: relative;

    &.collapsed {
        max-height: 200px;
        overflow: hidden;

        &:after {
            content: " ";
            display: block;
            width: 100%;
            height: 40px;
            position: absolute;
            background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
            bottom: 0;
            z-index: 1;
        }
    }

    &.expanded {
        max-height: none;
    }

    .expanderLink {
        position: absolute;
        bottom: 5px;
        left: 0;
        z-index: 2;
    }
}
