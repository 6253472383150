@import "./theme/idea-homes/color.scss";
@import "./theme/idea-homes/master.scss";



.wrap-sticky {
    height: 83px;
}

.btn-primary {
    background: var(--backgroundPrimary);
    color: var(--colorButtonPrimary);
    border: 0;

    &:hover {
        background: var(--backgroundSecondary);
        color: var(--colorButtonSecondary);
    }

    &:not(:disabled) {
        &:not(.disabled) {
            &.active, &:active {
                color: var(--colorButtonPrimary);
                background-color: var(--backgroundPrimary);
            }
        }
    }
}

.btn-secondary {
    background: var(--backgroundSecondary);
    color: var(--colorButtonSecondary);
    border: 0;

    &:hover {
        background: var(--backgroundPrimary);
        color: var(--colorButtonPrimary);
    }

    &:not(:disabled) {
        &:not(.disabled) {
            &.active, &:active {
                color: var(--colorButtonPrimary);
                background-color: var(--backgroundPrimary);
            }
        }
    }
}

.hero {
    width: 100vw;
    min-height: 40vw;
    display: flex;
    align-items: center;
    background-color: #ebebeb;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &.narrow {
        min-height: 25vw;
    }
}

.footer-bottom {
    &.bg-black-333 {
        padding-bottom: 35px;
    }
}

.lazyload {
    opacity: 0;
    transition: opacity 0.25s linear;
    will-change: opacity;

    &.fade-in {
        opacity: 1;
    }
}
