
/* ================================= */
/* ----------- COLORS CSS ---------- */
/* ================================= */

.text-theme-color-2 {
    color: var(--backgroundPrimary);
}

a:hover {
    color: var(--backgroundPrimary);
}

.bg-color-blue {
    background: var(--backgroundPrimary);
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
    background-color: var(--backgroundPrimary);
    color: var(--colorButtonPrimary) !important;
}

.line_6 {
    background: var(--backgroundPrimary);
}

a:hover,
a:focus {
    color: var(--backgroundPrimary);
}

.testinomial_wrap .testinomial_text::after {
    border-top: 20px solid var(--backgroundPrimary);
}

.blue_dark {
    background: var(--backgroundPrimary);
}

.pushmenu .push_nav .dropdown-menu li > a:hover {
    background: var(--backgroundPrimary);
}

.header-login {
    background: var(--backgroundPrimary);
}

#our-agent-slider .item:hover .team_text {
    background: var(--backgroundPrimary);
    transition: all 0.6s ease-in-out 0s;
}

.team_popup .progress-bar {
    background-color: var(--backgroundPrimary);
}

.pop_text > h3 {
    color: var(--backgroundPrimary);
}

.text-tag {
    background: var(--backgroundPrimary);
}

.query-title {
    background: var(--backgroundPrimary);
}

.single-query option {
    background: var(--colorButtonPrimary);
    color: #333;
}

.property-page-bg .query-submit-button.pull-right > input[type="submit"] {
    background: var(--backgroundPrimary);
}

.intro .dropdown li.current {
    background-color: var(--backgroundPrimary);
}

.feature-box .icon {
    background-color: var(--backgroundPrimary);
}

.feature-box .icon:after {
    border-color: transparent var(--backgroundPrimary) transparent transparent;
}

.link-arrow:hover::after {
    color: var(--backgroundPrimary);
}

.proerty_content .favroute ul li a:hover {
    background: var(--backgroundPrimary);
}

.feature .tag-2 {
    background: var(--backgroundPrimary);
}

#property-listing-slider .owl-next:hover,
#property-listing-slider .owl-prev:hover {
    background: var(--backgroundPrimary) !important;
}

#image-text .image-text-heading span {
    color: var(--backgroundPrimary);
}

.image-text-heading > a:hover {
    color: var(--backgroundPrimary);
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
    background: var(--backgroundPrimary);
}

.list.angle-double-right.list-border a:hover {
    color: var(--backgroundPrimary);
}

.team-block .team-thumb .styled-icons a:hover,
.work-gallery .gallery-thumb .styled-icons a:hover,
.work-gallery:hover .gallery-bottom-part,
.line-bottom-theme-colored-2::after,
.panel-group .panel-title a.active::after {
    background: var(--backgroundPrimary);
}

.line1,
.line2 {
    background-color: var(--backgroundPrimary);
}

.line1,
.line2 {
    background-color: var(--backgroundPrimary);
}

.btn-theme-colored {
    color: var(--backgroundPrimary);
}

.bg-theme-color-2 {
    background: var(--backgroundPrimary);
}

.styled-icons.icon-bordered a:hover {
    background-color: var(--backgroundPrimary);
}

.button {
}

.page-title a:hover {
    border-bottom: 1px solid var(--backgroundPrimary);
}

.line1,
.line2 {
    background-color: var(--backgroundPrimary);
}

.social-icons a {
    color: var(--backgroundPrimary);
}

#agent-p-2 .nstSlider .bar {
    background: var(--backgroundPrimary);
}

.feature-p-text > a:hover h4 {
    color: var(--backgroundPrimary);
}

.f-p-links > li a.active {
    background: var(--backgroundPrimary);
}

.f-p-links > li a:hover {
    background: var(--backgroundPrimary);
}

.my-pro .my-pro-list .button-my-pro-list > a {
    background: var(--backgroundPrimary);
}

#login .profile-login ul li a {
    color: var(--backgroundPrimary);
}

#login .profile-login ul .active > a {
    background: var(--backgroundPrimary);
}

#login .profile-login .nav > li > a:hover {
    color: var(--backgroundPrimary);
}

#login .profile-login .btn-slide:hover {
    color: var(--backgroundPrimary) !important;
}

#login .profile-login .lost-pass:hover {
    border-bottom: 1px solid var(--backgroundPrimary);
    color: var(--backgroundPrimary);
}

.wpf-demo-gallery .view-caption a:hover {
    color: var(--backgroundPrimary);
}

.previous_image_btn {
    background-color: var(--backgroundPrimary);
}

.next_image_btn {
    background-color: var(--backgroundPrimary);
}

.isotope-filters button {
    background-color: var(--backgroundPrimary);
}

.infowindow-markup {
    background: var(--backgroundPrimary);
    padding: 10px 20px;
}

.featured-properties-detail h4 {
    color: var(--backgroundPrimary);
}

.top .tag {
    background: var(--backgroundPrimary) !important;
}

.property-list-list {
    border-top: 3px solid var(--backgroundPrimary);
}

.property-list-list-info a > h3:hover {
    color: var(--backgroundPrimary);
}

.property-list-list .property-list-list-label::before {
    border-right: 17px solid var(--backgroundPrimary);
}

.multiple-recent-properties label {
    background-color: var(--backgroundPrimary);
}

.property-list-list ul li.right span {
    background-color: var(--backgroundPrimary);
}

.property-list-list .property-list-list-label::before {
    border-right: 17px solid var(--backgroundPrimary);
}

.property-list-list label {
    background-color: var(--backgroundPrimary);
}

.property-details .text-it-p {
    border-left: 6px solid var(--backgroundPrimary);
}

.property-details .pro-list > li:hover {
    color: var(--backgroundPrimary);
}

.property-tab .nav-tabs > li.active > a,
.property-tab .nav-tabs > li.active > a:focus,
.property-tab .nav-tabs > li.active > a:hover {
    background-color: var(--backgroundPrimary);
}

#agent-3-slider.owl-next:hover {
    background: var(--backgroundPrimary);
}

#agent-3-slider.owl-prev:hover {
    background: var(--backgroundPrimary);
}

#news-section-1 .add-on:hover {
    color: var(--backgroundPrimary);
}

.testinomial_wrap .testinomial_text::after {
    border-top: 20px solid var(--backgroundPrimary);
}

.blue_dark {
    background: var(--backgroundPrimary);
}

#main-slider .prev,
#main-slider .next {
    border: 1px solid var(--backgroundPrimary);
}

#main-slider .prev:hover,
#main-slider .next:hover {
    background-color: var(--backgroundPrimary);
    border: 1px solid var(--backgroundPrimary);
}

.loader {
    background: var(--backgroundPrimary);
}

.white_border:hover,
.white_border:focus {
    color: var(--backgroundPrimary);
}

.dark_border:hover,
.dark_border:focus {
    border: 1px solid var(--backgroundPrimary);
    background: var(--backgroundPrimary);
}

.skills .progress .progress-bar {
    background: var(--backgroundPrimary);
}

.header-login {
    background: var(--backgroundPrimary);
}

#header_top_3 .get-tuch i {
    color: var(--backgroundPrimary);
}

.pushmenu .push_nav .dropdown-menu li > a:hover {
    background: var(--backgroundPrimary);
}

#nav_slider .item .nav_tag.yellow {
    background: var(--backgroundPrimary);
}

#nav_slider .owl-next:hover,
#nav_slider .owl-prev:hover {
    background: var(--backgroundPrimary);
}

#nav_slider .item {
    background: var(--backgroundPrimary);
}

.owl-controls .owl-page.active span,
.owl-controls.clickable .owl-page:hover span {
    background: var(--backgroundPrimary);
}

#home_icon {
    background: var(--backgroundPrimary);
}

#home_icon .home_feature:hover h4,
#home_icon .home_feature:hover p,
#home_icon .home_feature:hover i {
    color: var(--backgroundPrimary);
}

.query-title {
    background: var(--backgroundPrimary);
}

.single-query option {
    background: #fff;
    color: #333
}

.property-page-bg .query-submit-button.pull-right > input[type="submit"] {
    background: var(--backgroundPrimary);
}

.intro .dropdown li.current {
    background-color: var(--backgroundPrimary);
}

.feature_box .icon {
    background-color: var(--backgroundPrimary);
}

.feature_box .icon:after {
    border-color: transparent var(--backgroundPrimary) transparent transparent;
}

.team-member .s-link a:hover,
.t-s-link a:hover {
    color: var(--backgroundPrimary);
}

.feature .tag-2 {
    background: var(--backgroundPrimary);
}

#image-text .image-text-heading span {
    color: var(--backgroundPrimary);
}

.list.angle-double-right.list-border a:hover {
    color: var(--backgroundPrimary);
}

.team-block .team-thumb .socials a:hover,
.work-gallery .gallery-thumb .socials a:hover,
.work-gallery:hover .gallery-bottom-part,
.line-bottom-theme-colored-2::after,
.panel-group .panel-title a.active::after {
    background: var(--backgroundPrimary);
}

.line1,
.line2 {
    background-color: var(--backgroundPrimary);
}

.btn-theme-colored {
    color: var(--backgroundPrimary);
}

.bg-theme-color-2 {
    background: var(--backgroundPrimary) !important;
}

.page-title a:hover {
    border-bottom: 1px solid var(--backgroundPrimary);
}

.line1,
.line2 {
    background-color: var(--backgroundPrimary) !important;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
    background: var(--backgroundPrimary);
}

#agent-p-2 .nstSlider .bar {
    background: var(--backgroundPrimary) !important;
}

.feature-p-text > h4 a:hover {
    color: var(--backgroundPrimary);
}

.feature-p-text > a {
    background: var(--backgroundPrimary);
}

.f-p-links > li a.active {
    background: var(--backgroundPrimary);
}

.f-p-links > li a:hover {
    background: var(--backgroundPrimary);
}

.my-pro .my-pro-list .button-my-pro-list > a {
    background: var(--backgroundPrimary);
}

#login .profile-login ul li a {
    color: var(--backgroundPrimary);
}

#login .profile-login ul .active > a {
    background: var(--backgroundPrimary);
}

#login .profile-login .nav > li > a:hover {
    color: var(--backgroundPrimary);
}

#login .profile-login .lost-pass {
    border-bottom: 1px solid var(--backgroundPrimary);
    color: var(--backgroundPrimary);
}

.wpf-demo-gallery .view-caption a:hover {
    color: var(--backgroundPrimary);
}

.previous_image_btn {
    background-color: var(--backgroundPrimary);
}

.next_image_btn {
    background-color: var(--backgroundPrimary);
}

.infowindow-markup {
    background: var(--backgroundPrimary);
}

.top .tag {
    background: var(--backgroundPrimary) !important;
}

#news-section-1 .add-on:hover {
    color: var(--backgroundPrimary);
}

.btn_fill {
    background: var(--backgroundPrimary);
}

a.back-to {
    background: var(--backgroundPrimary);
}

.pager li > a:hover,
.pager li > a:focus,
.pager li.active > .pager-item {
    background: var(--backgroundPrimary);
    border-color: var(--backgroundPrimary);
}
/*** Same hover Color ***/

.group-button-search {
    background: var(--backgroundPrimary);
}

.search_2_set {
    border-top: 5px solid var(--backgroundPrimary);
}

.property_item .price .tag,
.property_item .price h4 {
    background: var(--backgroundPrimary);
}

.inner-page-gallery-two-columns-dimension-btn a.active,
.inner-page-gallery-two-columns-dimension-btn a:hover,
.inner-page-gallery-three-columns-dimension-btn a.active,
.inner-page-gallery-three-columns-dimension-btn a:hover {
    background: var(--backgroundPrimary);
}

.featured-properties-detail h4 {
    color: var(--backgroundPrimary);
}

.navbar.bootsnav .navbar-toggle {
    background: var(--backgroundPrimary) !important;
}

.color_red {
    color: var(--backgroundPrimary);
}

.loader {
    background: var(--backgroundPrimary);
}

.socials li a:hover,
.socials li a:focus {
    background: var(--backgroundPrimary);
    border-color: var(--backgroundPrimary);
}

.bg_red {
    background: var(--backgroundPrimary);
}

.line_4,
.line_5,
.line_6 {
    background: var(--backgroundPrimary);
}

.link_arrow:hover::after,
.link_arrow:focus::after {
    color: var(--backgroundPrimary);
}

#header-top .header-top-links ul li a:hover {
    color: var(--backgroundPrimary);
}

.navbar.bootsnav .nav > li > a:hover,
.navbar.bootsnav .nav > li > a:focus,
.navbar.bootsnav .nav > li.active > a,
.navbar.bootsnav .nav > li.dropdown > .dropdown-toggle:hover,
.navbar.bootsnav .nav > li.dropdown.on > .dropdown-toggle,
.navbar.bootsnav ul.dropdown-menu.megamenu-content .content ul.menu-col li a:hover,
.navbar.bootsnav ul.dropdown-menu.megamenu-content .content ul.menu-col li a:focus {
    color: var(--backgroundPrimary) !important;
}

.navbar.bootsnav li.dropdown ul.dropdown-menu > li > a:hover,
.navbar.bootsnav li.dropdown ul.dropdown-menu > li > a:focus,
.navbar.bootsnav .nav li.dropdown ul.dropdown-menu > li > a:hover,
.navbar.bootsnav .nav li.dropdown ul.dropdown-menu > li > a:focus,
.navbar.bootsnav .nav > li.dropdown .dropdown-menu .dropdown-toggle:hover,
.navbar.bootsnav .nav .dropdown-menu li.dropdown.on .dropdown-toggle {
    background: var(--backgroundPrimary);
}

.bootsnav {
    .dropdown-item {
        &.active,
        &:active {
            background: var(--backgroundPrimary);

            &:hover {
                color: var(--headingColorBase);
            }
        }

        > a {
            color: var(--hoverPrimaryColor);
        }
    }
}

.pushmenu {
    border-top: 5px solid var(--backgroundPrimary);
}

.pushmenu .push_nav > li > a:hover,
.pushmenu .push_nav > li > a:focus,
.pushmenu .push_nav > li.active > a {
    color: var(--backgroundPrimary);
}

#partner_slider .item:hover img,
#partner_slider_2 .item:hover img {
    border: 1px solid var(--backgroundPrimary);
    cursor: pointer;
}

#nav_slider .owl-prev:hover,
#nav_slider .owl-next:hover,
#listing_slider .owl-next:hover,
#listing_slider .owl-prev:hover,
#property-listing-slider .owl-prev:hover,
#property-listing-slider .owl-next:hover,
#property-2-slider .owl-prev:hover,
#property-2-slider .owl-next:hover,
#property-d-1 .owl-prev:hover,
#property-d-1 .owl-next:hover,
#property-1-slider .owl-prev:hover,
#property-1-slider .owl-next:hover,
#agent-2-slider .owl-prev:hover,
#agent-2-slider .owl-next:hover,
#agent-3-slider .owl-next:hover,
#agent-3-slider .owl-next:focus,
#agent-3-slider .owl-prev:hover,
#agent-3-slider .owl-prev:focus {
    background: var(--backgroundPrimary);
}

#listing_slider .owl-next:hover,
#listing_slider .owl-prev:hover,
#property-listing-slider .owl-prev:hover,
#property-listing-slider .owl-next:hover,
#property-2-slider .owl-prev:hover,
#property-2-slider .owl-next:hover,
#property-d-1 .owl-prev:hover,
#property-d-1 .owl-next:hover,
#property-1-slider .owl-prev:hover,
#property-1-slider .owl-next:hover,
#agent-2-slider .owl-prev:hover,
#agent-2-slider .owl-next:hover {
    border-color: var(--backgroundPrimary);
}

#project .cbp-filter-item.cbp-filter-item-active,
#project .cbp-filter-item:hover {
    background: var(--backgroundPrimary);
}

.property_meta {
    background: var(--backgroundPrimary);
}

.mb20 form a.button {
    background: var(--backgroundPrimary);
}

.map-detail h4 {
    color: var(--backgroundPrimary);
}

.image-label label {
    background-color: var(--backgroundPrimary);
}

.bg-color-red {
    background: var(--backgroundPrimary);
}

.short-msg a.short-topup {
    background: var(--backgroundPrimary);
}

.nav-tabs > li > a > .fa {
    color: var(--backgroundPrimary);
}

.short-msg-tab .tab-pane h3 {
    border-bottom: 1px solid var(--backgroundPrimary);
}

.team-box ul li a {
    color: var(--backgroundPrimary);
}

.team-box .team-box-overlay {
    background: var(--backgroundPrimary);
}

.team-box .team-box-overlay {
    background: var(--backgroundPrimary);
}

#main_header.header_sev #header-top .header-top-links .header-login:hover {
    color: var(--backgroundPrimary);
}

.rev_slider_wrapper #rev_slider_3 .slide-content {
    border-left: 3px solid var(--backgroundPrimary);
}

.rev_slider_wrapper #rev_slider_3 .slide-content .title {
    color: var(--backgroundPrimary);
}

.banner-search button {
    background-color: var(--backgroundPrimary);
}

#banner-nin .banner_detail h2 span {
    color: var(--backgroundPrimary);
}

#typewriter-text {
    color: var(--backgroundPrimary);
}

#property-search-sev .nstSlider {
    background-color: var(--backgroundPrimary);
}

.media.service-box .pull-left {
    color: var(--backgroundPrimary);
}

.media.service-box .pull-left::after {
    background: var(--backgroundPrimary);
}

.media.service-box:hover .pull-left {
    background-color: var(--backgroundPrimary);
}

.number-counters .counters-item i {
    color: var(--backgroundPrimary);
}

.number-counters .counters-item p {
    color: var(--backgroundPrimary);
}

#services-nin .nav-tabs > li.active > a,
#services-nin .nav-tabs > li > a:focus,
#services-nin .nav-tabs > li > a:hover,
#services-nin .nav-tabs .dropdown-menu > li.active > a,
#services-nin .nav-tabs .dropdown-menu > li > a:focus,
#services-nin .nav-tabs .dropdown-menu > li > a:hover {
    background-color: var(--backgroundPrimary);
}

.services-list li::before {
    color: var(--backgroundPrimary);
}

#services-nin .member-thumb .overlay2 .overlay_inner h3 {
    border-bottom: 2px solid var(--backgroundPrimary);
}

#services-nin .member-thumb .overlay2 .overlay_inner h3 span {
    color: var(--backgroundPrimary);
}

#services-nin .member-thumb .overlay2 .overlay_inner a.btn-icon {
    color: var(--backgroundPrimary);
}

#services-nin .member-thumb .overlay2 .overlay_inner a.btn-icon:hover {
    background: var(--backgroundPrimary);
}

.call-to-action2 h2 span {
    color: var(--backgroundPrimary);
}

.about-sev-tag h4 {
    color: var(--backgroundPrimary);
}

.about-sev-tag ul li i {
    color: var(--backgroundPrimary);
}

.about-sev-tag .founder-text h3 {
    color: var(--backgroundPrimary);
}

.about-detail h4 {
    border-left: 5px solid var(--backgroundPrimary);
}

.custom-content h5 {
    color: var(--backgroundPrimary);
}

.page-section-ptb .pro-video a {
    color: var(--backgroundPrimary);
}

.our-mission-box-detail h3 {
    color: var(--backgroundPrimary);
}

.our-mission-box-detail ul li::before {
    color: var(--backgroundPrimary);
}

#services-page.our-value .services-page-box h2::after {
    background: var(--backgroundPrimary);
}

.services-page-box h2 {
    color: var(--backgroundPrimary);
}

.single-effect3 .info {
    background: var(--backgroundPrimary);
}

#faq2 .panel-default > .panel-heading {
    background-color: var(--backgroundPrimary);
}

.news_padding > p > a:hover {
    color: var(--backgroundPrimary);
}

.news_image .price .tag_red {
    background: var(--backgroundPrimary);
}

#about_slider .owl-page.active span,
#about_slider .owl-page:hover span {
    background: var(--backgroundPrimary);
}

.profile-list li a:hover, .profile-list li.active a {
    background: var(--backgroundPrimary);
}

.dropzone.profile-picture .dz-default.dz-message {
    background-color: var(--backgroundPrimary);
}

.dropzone {
    border: 3px solid var(--backgroundPrimary);
}

.profile-login-bg h2 span {
    color: var(--backgroundPrimary);
}

.profile-login-bg h2 span {
    color: var(--backgroundPrimary);
}

.agency-listing .agency-listing-box .image-round span {
    background: var(--backgroundPrimary);
}

.agency-box h3 .fa {
    color: var(--backgroundPrimary);
}

.address-box .fa {
    background-color: var(--backgroundPrimary);
}

.calc .btn {
    background: var(--backgroundPrimary);
}

.calc .fa {
    background-color: var(--backgroundPrimary);
}

.calc .reset {
    background: var(--backgroundPrimary);
}

.file_uploader {
    background: var(--backgroundPrimary);
}

.button-normal.yellow {
    background-color: var(--backgroundPrimary);
}

.block-menu li a.active,
.block-menu li a:hover {
    background-color: var(--backgroundPrimary);
    border: 1px solid var(--backgroundPrimary);
    border-right-color: var(--backgroundPrimary);
}

#address-text {
    background: var(--backgroundPrimary);
}

#address-text .fa {
    color: var(--backgroundPrimary);
}

.pricing-box {
    background: var(--backgroundPrimary);
}

.pricing-box .btn.btn-default.select-button:focus,
.pricing-box .btn.btn-default.select-button:hover {
    background-color: var(--backgroundPrimary);
}

#new-property .card-list li.active:after {
    background-color: var(--backgroundPrimary);
}

#new-property #position {
    background-color: var(--backgroundPrimary);
}

#new-property #position:after {
    border-bottom: 10px solid var(--backgroundPrimary);
}

.planContainer .price p.bestPlanPrice {
    background: var(--backgroundPrimary);
}

.planContainer .button a.bestPlanButton {
    background: var(--backgroundPrimary);
    border: 2px solid var(--backgroundPrimary);
}

.planContainer .button a.bestPlanButton:hover {
    background: var(--backgroundPrimary);
    border: 1px solid var(--backgroundPrimary);
}

.planContainer .button a {
    color: var(--backgroundPrimary);
    border: 1px solid var(--backgroundPrimary);
}

.planContainer .button a:hover {
    background: var(--backgroundPrimary);
}

.slider-text {
    border-left: 5px solid var(--backgroundPrimary);
}

.gallery .item-detail {
    background-color: var(--backgroundPrimary);
}


/********** RBGA COLORS ************/
.right_box {
    background: rgba(237, 42, 40, .9);
}

.wpf-demo-gallery:hover .view-caption {
    background-color: rgba(237, 40, 42, 0.9);
}

.inner-page-gallery-two-columns-dimension-detail .image_description,
.inner-page-gallery-three-columns-dimension-detail .image_description {
    background: rgba(237, 42, 40, 0.9);
}

#team {
    background-color: rgba(0, 0, 0, 0.9);
}
